.topo {
  color: $c-white;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  width: 50%;

  overflow: hidden;

  &--hero {
    color: $c-primary;
    opacity: 0.3;
  }

  @media screen and (max-width: $bp-nav-social) {
    width: 100% !important;
  }

}
