.blazy {
  animation: none;
  opacity: 0;

  &.is-loaded {
    visibility: visible;
    animation: reveal 0.6s ease 0s 1 forwards;
  }
}

.pil {
  position: relative;
  overflow: hidden;
  height: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $c-grey-3;
  // padding-bottom set in helpers

  > div,
  > img {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    margin: auto;
    // stretches the cross image
    width: 100%;
    height: 100%;
  }
}
