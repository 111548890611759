* { box-sizing: border-box; }

::-moz-selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: $c-primary;
}
::selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: $c-primary;
}
a::-moz-selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: $c-white;
}
a::selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: $c-white;
}

html, body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 62.5%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
body {
  font-family: $ff-base;
  font-weight: $fw-base;
  font-size: $fs-base;
  line-height: 1.5;

  color: $c-text-base;
  background-color: $c-bg-base;

  transition: 0.2s ease opacity;
  opacity: 0;

  .has-js.no-fonts &,
  .has-js.has-fonts & {
    opacity: 1;
  }

  &.is-konami {}

  @media screen and (max-width: $bp-site-desktop) {
    font-size: 1.6rem;
  }
}

img {
  max-width: 100%;
}

p {
  strong {
    color: $c-white;
  }
}

p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

a {
  color: $c-primary;
  text-decoration: none;
  transition:
    $t-link-timing $t-link-easing border-color,
    $t-link-timing $t-link-easing color;
  border-bottom: 1px solid currentColor;
  -webkit-tap-highlight-color: transparent;
}
a:focus,
a:hover {
  border-bottom-color: currentColor;
}
a:active {}
a:focus {
  outline: none;
}

button {
  border: 0;
  background: transparent;
  line-height: 1;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: currentColor;
  display: inline-block;
  text-align: center;

  -webkit-tap-highlight-color: transparent;

  transition:
    0.2s $easeOutExpo color;

  &:focus,
  &:active {
    outline: none;
  }
}
