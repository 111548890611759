.readmore {

  &__excerpt {
    display: none;
    visibility: hidden;

    .is-truncated & {
      display: block;
      visibility: visible;
    }
  }

  &__full {
    display: block;
    visibility: visible;

    .is-truncated & {
      display: none;
      visibility: hidden;
    }
  }

  &__btn {
    font-size: 1.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin-top: 0.6em;
    color: $c-white;
    font-weight: $fw-light;

    .icon {
      margin-left: 0.2em;
      position: relative;
      top: -0.1em;

      svg {
        transform: rotateX(-180deg);
        transform-origin: 50% 50%;
        transition: 0.4s $easeOutExpo transform;
      }
    }

    .is-truncated & {
      .icon svg {
        transform: rotateX(0deg);
      }
    }

    @media screen and (max-width: $bp-films-2up-break) {
      font-size: 1.6rem;
    }
  }
}
