.section-title {
  margin-bottom: 10rem;
  text-align: center;

  @media screen and (max-width: $bp-nav-social) {
    margin-bottom: 6rem;
  }

  &__title {
    font-weight: $fw-semibold;
    color: $c-white;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 3.6rem;
    letter-spacing: 0.05em;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2.8rem;
    }
  }

}
