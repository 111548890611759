.page-header {
  margin-bottom: 10rem;

  @media screen and (max-width: $bp-site-desktop) {
    margin-bottom: 8rem;
  }

  &__inner {}

  &__title {
    font-weight: $fw-semibold;
    color: $c-primary;
    margin-top: 0;
    margin-bottom: 0.9em;
    text-transform: uppercase;
    font-size: 3.6rem;
    letter-spacing: 0.05em;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2.8rem;
      margin-bottom: 1.1em;
    }
  }

  &__intro {
    font-size: 3.6rem;
    max-width: 25em;
    line-height: 1.3;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2.6rem;
      line-height: 1.45;
    }
  }

  &__description {
    margin-top: 3.6rem;
    font-size: 1.8rem;
    max-width: 40em;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 1.6rem;
    }
  }

}
