.team-members {

  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 820px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    $width: #{69rem / $d-site * 100 + '%'};

    flex: 0 1 $width;
    width: 50%;
    padding-right: $d-gutter;
    margin-bottom: 10rem;

    &:nth-child(2n+0) {
      margin-right: 0;
      padding-right: 0;
    }

    @media screen and (max-width: $bp-site-desktop) {
      margin-bottom: 5rem;
    }

    @media screen and (max-width: 820px) {
      width: 100%;
      margin-right: 0;
      padding-right: 0;
      flex: none;
    }

  }

}

.team-member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: $bp-site-desktop) {
    flex-direction: column;
  }

  @media screen and (max-width: 820px) {
    text-align: center;
  }

  &__image {
    display: block;
    border: 0;
    flex: 1 0 auto;
    width: 33rem;
    max-width: 33rem;
    height: 33rem;
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    margin-right: 6rem;
    position: relative;
    overflow: hidden;

    &--link {
      transition: $t-media-timing $t-media-easing filter;
    }

    @media screen and (max-width: $bp-site-desktop) {
      margin-right: 0;
    }

    @media screen and (max-width: 820px) {
      width: 33rem;
      height: 33rem;
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
    }

    &--link:hover,
    &--link:focus {
      filter: $t-media-filter;
    }

    .figure {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .pil {
    }

  }

  &__content {
    margin-top: 3.4rem;
  }

  &__title {
    font-weight: $fw-semibold;
    margin-top: 0;
    margin-bottom: 0.8em;
    text-transform: uppercase;
    font-size: 2.6rem;
    line-height: 1.2;
    letter-spacing: 0.05em;
  }

  &__roles {}

  &__role {
    display: inline-block;
    margin-right: 0.1em;

    &:not(:last-of-type):after {
      content: "•";
      display: inline-block;
      margin-left: 0.3em
    }
  }

  &__social {
    margin-top: 4rem;

    .contact__link__item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

}
