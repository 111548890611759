.profile {

  .page-header {
    margin-bottom: 6rem;
  }

  .page-header__title {
    color: $c-white;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media screen and (max-width: $bp-profile) {
      flex-wrap: wrap;
    }

  }

  &__image {
    max-width: 46rem;
    flex: 1 0 100%;
    margin-right: 12rem;
    margin-bottom: 8rem;

    @media screen and (max-width: $bp-site-desktop) {
      margin-right: 8rem;
      max-width: 42rem;
    }

    @media screen and (max-width: $bp-profile) {
      order: 2;
      margin-right: 0;
      margin-bottom: 6rem;
    }
  }

  &__details {
    max-width: 60rem;

    a {
      border-bottom-color: transparent;

      &:hover,
      &:active {
        border-bottom-color: currentColor;
      }
    }

    blockquote {
      margin: 1em 5rem 2em;

      @media screen and (max-width: $bp-site-desktop) {
        margin-left: 4rem;
        margin-right: 4rem;
      }
      @media screen and (max-width: $bp-nav-menu) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }

    @media screen and (max-width: $bp-profile) {
      max-width: none;
      margin-bottom: 0rem;
    }

    &--additional {
      margin-bottom: 6rem;
    }

  }

  &--main {
    justify-content: space-between;

    .page-header__title {
      color: $c-primary;
    }

    .profile__image {
      order: 2;
      max-width: 60rem;
      margin-right: 0;
      margin-left: 12rem;

      @media screen and (max-width: $bp-site-desktop) {
        margin-left: 8rem;
        max-width: 42rem;
      }

      @media screen and (max-width: $bp-profile) {
        margin-left: 0;
      }
    }

    .profile__readmore {
      font-size: 2rem;
      @media screen and (max-width: $bp-films-2up-break) {
        font-size: 1.6rem;
      }
    }
  }

}
