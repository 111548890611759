.bar-link {
  letter-spacing: $letter-spacing;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid $c-black;
  font-weight: $fw-book;

  a {
    color: $c-white;
    border: 0;
    line-height: 1;
    display: block;
    padding: 4.8rem 2rem;
    background-color: transparent;

    transition:
      $t-link-timing $t-link-easing color,
      $t-link-timing * 2 $t-link-easing background-color;

    @media screen and (max-width: $bp-nav-menu) {
      padding: 3rem 2rem;
    }

    .icon {
      margin-left: 0.4em;
      position: relative;
      top: 0.05em;
      transition: $t-link-timing $t-link-easing transform;
      transform: translateY(0) translateX(0);
    }

    svg {
      color: $c-primary;
    }

    &:hover {
      transition-duration:
        $t-link-timing,
        $t-link-timing * 4;

      color: $c-primary;
      background-color: $c-black;

      .icon {
        transform: translateY(0) translateX(0.7em);
      }
    }
  }

  &--external {
    a:hover {
      .icon {
        transform: translateY(-0.7em) translateX(0.7em);
      }
    }
  }

}
