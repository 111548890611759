.icon {

  display: inline-block;
  width: 2.4rem;

  &--logo {
    width: 24.5rem;
    @media screen and (max-width: $bp-nav-menu) {
      width: 20rem;
    }
  }

  &--facebook {
    width: 1.4rem;
  }

  &--vimeo {
    width: 2.2rem;
  }

  &--arrow-ne {
    width: 1.2rem;
  }

  &--arrow-w {
    width: 1.8rem;
  }

  &--arrow-s {
    width: 1.1rem;
  }

  &--close {
    width: 2.7rem;
  }

  &--menu {
    width: 2.7rem;
  }

  &--play {
    width: 1rem;
  }

  &--play-btn {
    width: 15rem;
    height: 15rem;
  }

  &--logo-monogram {
    width: 35.7rem;

    .letter-f {
      color: $c-primary;
    }
  }

  &--chevron-down {
    width: 1.2rem;
  }

  &--map {
    width: 7.4rem;

    .pin {
      color: $c-primary;
    }
    .pinhole {
      color: $c-black;
    }
  }

  &--world-map {
    width: 117.6rem;
  }
}
