.page {

  // ---------------------------------------------------------------------------
  // HOME PAGE                                                         HOME PAGE
  // ---------------------------------------------------------------------------
  &--home {
    .header {
      position: relative;
      z-index: 4;
    }
    .section--hero {
      padding: calc(#{$d-header-large} - 2.5rem) $d-gutter 9rem;
      margin-top: -#{$d-header-large};
      overflow: hidden;
      position: relative;
      background-color: $c-bg-hero;

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: $d-header-large;
      }

      @media screen and (max-width: $bp-carousel-2) {
        padding-bottom: $d-gutter;
      }

      @media screen and (max-width: $bp-site-small) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .section__inner {
        max-width: #{$d-site + 30rem};
        margin-left: auto;
        margin-right: auto;
      }

      .topo--hero {
        top: 0;
        right: 0;
        width: 100%;
        opacity: 0.3;
        z-index: 2; // above the carousel

        position: absolute;
        bottom: 0;
        margin: auto;

        text-align: center;

        svg {
          height: 100%;
        }

      }


    }
    .section--latest-film {
      padding-top: 9rem;
      padding-bottom: 16rem;

      @media screen and (max-width: $bp-nav-menu) {
        padding-bottom: 10rem;
      }
    }
    .section--recent-films {
      padding-top: 0;
      padding-bottom: 0;
    }
    .section--social {
      padding-top: 12rem;
      padding-bottom: 16rem;
      position: relative;

      .topo {
        &--one {
          top: 0;
          left: 0;
        }
        &--two {
          right: 0;
          top: 50%;
        }
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }
    }
    .section--contact {
      padding-bottom: 18rem;
      position: relative;
      overflow: hidden;

      .topo {
        top: 0;
        left: 0;
        width: 30%;
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-bottom: 6rem;
        text-align: center;
        .contact__header {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .films__item {
      margin-bottom: 12rem;

      @media screen and (max-width: $bp-nav-menu) {
        margin-bottom: 6rem;
      }
    }
  }


  // ---------------------------------------------------------------------------
  // ABOUT PAGE                                                       ABOUT PAGE
  // ---------------------------------------------------------------------------
  &--about {

    .header {
      position: relative;
      z-index: 1;
    }

    .section--mainprofile {
      padding-top: calc(#{$d-gutter} + #{$d-header-large});
      margin-top: -#{$d-header-large};
      overflow: hidden;
      position: relative;

      .topo {
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;

        svg {
          height: 100%;
          transform: translateX(-10%);
        }

        @media screen and (max-width: $bp-nav-social) {
          svg {
            height: auto;
            transform: none;
          }
        }
      }
    }
    .section--otherprofiles {
      position: relative;
      overflow: hidden;
      padding-bottom: 16rem;

      .topo {
        right: 0;
        bottom: 0;
        width: 50vw;
        color: $c-white;
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-bottom: 6rem;
      }
    }

    .section--world-map {
      padding-top: 15rem;
      padding-bottom: 15rem;
      overflow: hidden;

      .section__inner,
      .container {
        height: 100%;
      }

      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: $bp-nav-social) {
          justify-content: center;
        }
      }
      .page-header {
        padding-top: 25rem;
        padding-bottom: 25rem;
        position: relative;
        z-index: 1;
        max-width: 56rem;
        margin-bottom: 0;

        @media screen and (max-width: $bp-nav-social) {
          padding-top: 8rem;
          padding-bottom: 8rem;
        }

        &__title {
          color: $c-white;
        }
      }
      .icon--world-map {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 100vw;
        max-width: 150rem;
        min-width: 120rem;

        @media screen and (max-width: $bp-nav-menu) {
          left: calc(50vw + 10rem);
        }
      }
    }

  }


  // ---------------------------------------------------------------------------
  // FILMS PAGE                                                       FILMS PAGE
  // ---------------------------------------------------------------------------
  &--films {
    .section--pageheader {
      padding-top: 6rem;
      padding-bottom: 0;

      .topo {
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;

        svg {
          height: 100%;
          transform: translateX(-10%);
        }

        @media screen and (max-width: $bp-nav-social) {
          svg {
            height: auto;
            transform: none;
          }
        }
      }
    }

    .section--featuredfilms {
      padding-top: 0;
      padding-bottom: 0;
    }

    .section--otherfilms {
      padding-top: 13rem;
      padding-bottom: 0;

      position: relative;
      overflow: hidden;

      .topo--one {
        right: 0;
        top: 0;
      }

      .topo--four {
        left: 0;
        bottom: 0;
        width: 40rem;

        @media screen and (max-width: $bp-nav-social) {
          display: none;
        }
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 6rem;
      }
    }
  }


  // ---------------------------------------------------------------------------
  // STILLS PAGE                                                     STILLS PAGE
  // ---------------------------------------------------------------------------
  &--stills {
    .section--pageheader {
      padding-top: 6rem;
      padding-bottom: 0;

      .topo {
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;

        svg {
          height: 100%;
          transform: translateX(-10%);
        }

        @media screen and (max-width: $bp-nav-social) {
          svg {
            height: auto;
            transform: none;
          }
        }
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 3rem;
      }
    }
    .section--gallery {
      padding-top: 7rem;
      position: relative;
      overflow: hidden;

      .topo {
        bottom: 0;
        left: 0;
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 0;
      }
    }
  }


  // ---------------------------------------------------------------------------
  // CONTACT PAGE                                                   CONTACT PAGE
  // ---------------------------------------------------------------------------
  &--contact {
    .section--pageheader {
      padding-top: 6rem;
      padding-bottom: 22rem;

      .topo {
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;

        svg {
          height: 100%;
          transform: translateX(-10%);
        }

        @media screen and (max-width: $bp-nav-social) {
          svg {
            height: auto;
            transform: none;
          }
        }
      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 3rem;
        padding-bottom: 6rem;
      }

    }
  }

  // ---------------------------------------------------------------------------
  // ERROR PAGE                                                       ERROR PAGE
  // ---------------------------------------------------------------------------
}
