.hero-carousel-wrapper {
  position: relative;

}

.hero-carousel {
  height: 0;
  padding-bottom: $d-carousel-percentage;

  @media screen and (max-width: $bp-nav-menu) {
    position: relative;
  }

  .swiper-wrapper {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  &__slide {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $c-grey-3;

    transition:
      0s transform,
      2s ease-out opacity !important;

    &.swiper-lazy {
      opacity: 0 !important;
    }

    &.swiper-lazy-loaded.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: $d-gutter;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $bp-nav-menu) {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    @media screen and (max-width: $bp-carousel-2) {
      position: relative;
      bottom: auto;
      padding: 0;
    }

    &__inner {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding-bottom: 6vw;

      @media screen and (max-width: $bp-carousel-2) {
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0;
      }

      .icon {
        svg {
          stroke-width: 1;
        }
      }

      .icon--logo-monogram {
        margin-right: 8%;
        max-width: 28rem;

        @media screen and (max-width: $bp-carousel-2) {
          margin: auto;
          width: 30vw;
          position: absolute;
          top: calc(-56.25vw + 5rem);
          height: 0;
          padding-bottom: 56.25vw;

          svg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;

            @media screen and (max-width: $bp-nav-menu) {
              stroke-width: 2;
            }
          }
        }

        @media screen and (max-width: $bp-nav-menu) {
          width: 30vw;
          min-width: 0;
        }

        @media screen and (max-width: $bp-site-small) {
          top: calc(-56.25vw + 3rem);
        }

      }
    }

    &__content {
      font-size: 3.6rem;
      line-height: 1.2;
      max-width: 21em;
      color: $c-white;
      flex: 1 0 70%;
      position: relative;

      @media screen and (min-width: $bp-carousel-2) and (min-height: $bp-carousel-height-1) {
        flex: none;
      }

      @media screen and (max-width: $bp-site-desktop) {
        font-size: 2.8rem;
        padding: $d-gutter 0;
        line-height: 1.45;
      }

      @media screen and (max-width: $bp-carousel-3) {
        font-size: 2.2rem;
      }
    }
  }

  &__location {
    margin-top: 4rem;
    font-size: 2.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 2.5rem;
      color: $c-white;
      position: relative;
      top: 0.36em;
    }

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2rem;

      .icon {
        width: 6rem;
      }
    }

    @media screen and (max-width: $bp-carousel-3) {
      font-size: 1.8rem;
      .icon {
        width: 5rem;
      }
    }
  }

  &__btn {
    color: $c-primary;
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: calc(100% + 8rem);
    left: 0;

    .icon {
      transition: $t-link-timing $t-link-easing transform;
      margin-top: 3rem;
      transform: translateY(0);
    }

    &:hover,
    &:active {
      color: $c-white;
      background-color: transparent;

      .icon {
        transform: translateY(0.7em);
      }
    }

    @media screen and (max-width: $bp-site-desktop) {
      top: calc(100% + 4rem);
    }

    @media screen and (max-width: $bp-nav-social) {
      top: calc(100% + 2rem);
      flex-direction: row;

      .icon {
        margin-top: 0;
        margin-left: 2rem;
      }
    }

    @media screen and (max-width: $bp-carousel-2) {
      position: relative;
      margin-top: 6rem;
      top: 0;
    }

  }
}
