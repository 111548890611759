.nav {
  text-transform: uppercase;
  font-size: $fs-nav;
  line-height: 1;
  font-weight: $fw-book;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  &__logo {
    display: block;
    color: $c-white;
    border: none;

    transition: $t-link-timing $t-link-easing color;

    path {
      transition: $t-link-timing $t-link-easing;
    }

    &:hover,
    &:focus {
      .icon {
        color: $c-primary;
      }
    }
  }

  &__menu-btn {

    color: $c-primary;

    &:hover,
    &:active {
      color: $c-white;
    }

    &--open {
      display: none;
      visibility: hidden;

      @media screen and (max-width: $bp-nav-menu) {
        display: block;
        visibility: visible;
      }
    }

    &--close {
      visibility: hidden;
      position: absolute;
      z-index: 5;
      top: calc(#{$d-gutter} + 1.4rem);
      right: $d-gutter;

      pointer-events: none;
      opacity: 0;
      transition: $t-menu-timing $t-menu-easing;

      @media screen and (max-width: $bp-nav-menu) {

        .is-open & {
          visibility: visible;
          opacity: 1;
          pointer-events: initial;
        }
      }

      @media screen and (max-width: $bp-site-small) {
        right: $d-gutter / 2;
      }
    }
  }

  &__menu {

    @media screen and (max-width: $bp-nav-menu) {
      visibility: hidden;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;

      &.is-open {
        visibility: visible;
      }
    }
  }

  &__social,
  &__pages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__pages {
    .topo {
      visibility: hidden;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
    }

    @media screen and (max-width: $bp-nav-menu) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: $c-bg-hero;

      opacity: 0;

      .is-open & {
        transition: $t-menu-timing $t-menu-easing;
        opacity: 1;
      }

      .topo {
        visibility: visible;
        display: block;
      }
    }
  }

  &__social {
    @media screen and (max-width: $bp-nav-social) {
      display: none;
    }
  }

  &__item {
    margin: 0 2rem;

    &--home {
      display: none;
    }

    .nav__social & {
      margin: 0 1.4rem;

      @media screen and (max-width: $bp-site-desktop) {
        margin: 0 1rem;

        .icon {
          width: 2rem;
        }
        .icon--facebook {
          width: 1.2rem;
        }
      }
    }

    .nav__social &,
    .nav__pages & {
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .nav__pages & {

      @media screen and (max-width: $bp-site-desktop) {
        margin: 0 1.2rem;
      }

      @media screen and (max-width: $bp-nav-menu) {
        display: flex !important;
        margin: .6em 0;
        width: 80%;
        text-align: center;

        font-size: 2rem;

        a {
          width: 100%;
          border-bottom: 0;
          padding: 1em 2em;

          &.is-active,
          &:hover,
          &:active {
            color: $c-white;
          }
        }
      }
    }

  }

  &__link {
    border-bottom-color: transparent;
    border-bottom-width: 2px;
    letter-spacing: $letter-spacing;
    padding: 0.4em 0.7em 0.4em 0.9em;

    &--icon {
      display: block;
      border: none;
    }

    .nav__social & {
      padding-left: 0;
      padding-right: 0;
    }

    &.is-active,
    &:hover {
      border-bottom-color: currentColor;

      .nav__social & {
        color: $c-white;
      }
    }
  }

}
