.section {
  padding-top: 12rem;
  padding-bottom: 12rem;

  @media screen and (max-width: $bp-nav-menu) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__inner {
    position: relative;

    .section--indent & {
      margin-left: $d-barcode-indent;

      @media screen and (max-width: $bp-nav-menu) {
        margin-left: $d-barcode-indent / 2;
      }

      @media screen and (max-width: 460px) {
        margin-left: 0;
      }
    }
  }

  &--lighter {
    background-color: $c-section-lighter;
  }
}
