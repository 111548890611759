.films {

  &__inner {

  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    $width: #{46rem / $d-site * 100 + '%'};
    $margin-right: #{6rem / $d-site * 100 + '%'};
    $margin-bottom: #{15rem / $d-site * 100 + '%'};

    flex: 0 1 $width;
    margin-right: $margin-right;
    margin-bottom: $margin-bottom;

    &:nth-child(3n+0) {
      margin-right: 0;
    }

    .films--2 & {
      $width: #{72rem / $d-site * 100 + '%'};

      flex: 0 1 $width;
      margin-right: $margin-right;

      &:nth-child(2n+0) {
        margin-right: 0;
      }

      @media screen and (max-width: $bp-films-2up-break) {
        width: 100%;
        flex: 1 0 100%;
        margin-right: 0;
      }

    }

    .films--3 & {

      @media screen and (max-width: $bp-films-3up-break) {
        $width: #{72rem / $d-site * 100 + '%'};

        flex: 0 1 $width;
        margin-right: $margin-right;

        &:nth-child(2n+0) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: $bp-films-2up-break) {
        width: 100%;
        flex: 1 0 100%;
        margin-right: 0;
      }
    }
  }

}

// -----------------------------------------------------------------------------
// FILM                                                                     FILM
// -----------------------------------------------------------------------------

.film {

  &__image {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: $d-film-image-percentage;

    .pil {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translateY(-50%) translateX(-50%);
    }

    .figure {
      transition: $t-media-timing $t-media-easing filter;
    }

    .js-vimeo-lightbox:hover .figure,
    .js-vimeo-lightbox:focus .figure {
      filter: $t-media-filter;
    }

    .icon--play-btn {
      color: $c-white;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transition: #{$t-link-timing * 2} $t-link-easing color;

      .film--hero & {
        width: 30rem;
        height: 30rem;
        left: 75%;
        transform: translateX(calc(-50% / 0.75));

        @media screen and (max-width: $bp-site-desktop) {
          width: 19rem;
          height: 19rem;
        }

        @media screen and (max-width: $bp-nav-menu) {
          left: 0;
          right: 0;
          transform: translateX(0);
        }

        @media screen and (max-width: $bp-films-2up-break) {
          width: 15rem;
          height: 15rem;
        }
      }

      @media screen and (max-width: 440px) {
        transform: translateX(0) scale(0.8) !important;
      }
    }

    .js-vimeo-lightbox:hover .icon--play-btn,
    .js-vimeo-lightbox:focus .icon--play-btn {
      color: $c-primary;
    }
  }

  &__play-btn {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__details {
    margin-top: 6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    overflow: hidden;

    .films--2 & {
      padding-left: 6rem;
      padding-right: 6rem;

      @media screen and (max-width: $bp-site-desktop) {
        margin-top: 4rem;
        padding-left: 4rem;
        padding-right: 4rem;
      }

      @media screen and (max-width: $bp-nav-social) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .films--3 & {

      @media screen and (max-width: $bp-site-desktop) {
        margin-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (max-width: $bp-films-3up-break) {
        margin-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (max-width: $bp-nav-social) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

    }

    .film--hero & {
      margin-top: 8rem;
      overflow: auto;
      padding-left: 6rem;
      padding-right: 6rem;

      @media screen and (max-width: $bp-site-desktop) {
        margin-top: 4rem;
        padding-left: 4rem;
        padding-right: 4rem;
      }

      @media screen and (max-width: $bp-nav-menu) {
        margin-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

  }

  &__header {

  }

  &__label {
    font-size: 1.4rem;
    color: $c-primary;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: $fw-semibold;
    letter-spacing: $letter-spacing;

    .film--hero & {
      font-size: 1.8rem;
      margin-bottom: 4.6rem;

      @media screen and (max-width: $bp-site-desktop) {
        margin-bottom: 2.2rem;
        font-size: 1.4rem;
      }

      @media screen and (max-width: $bp-films-2up-break) {
        margin-bottom: 0.8rem;
      }
    }

    &:empty {
      &:after {
        content: '—';
        display: block;
      }
    }
  }

  &__title {
    font-size: 3.6rem;
    font-weight: $fw-semibold;
    color: $c-white;
    text-transform: uppercase;
    margin: 0 0 3rem;
    line-height: 1.1;
    letter-spacing: 0.05em;

    .film--hero .film__image & {
      font-size: 8rem;
      position: absolute;
      top: 50%;
      left: 0rem;
      z-index: 3;
      padding: 0 6rem;
      transform: translateY(-50%);
      max-width: calc(50% + 6rem); // - half
      text-align: left;
      margin: 0;

      @media screen and (max-width: $bp-site-desktop) {
        font-size: 6rem;
      }

      @media screen and (max-width: $bp-nav-menu) {
        display: none;
        visibility: hidden;
      }

    }

    .film--hero .film__details & {
      visibility: hidden;
      display: none;
      font-size: 3.6rem;

      @media screen and (max-width: $bp-nav-menu) {
        visibility: visible;
        display: block;
      }

      @media screen and (max-width: $bp-films-2up-break) {
        font-size: 3.2rem;
      }

    }

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: $bp-nav-menu) {
      margin-bottom: 2rem;
    }
  }

  &__description {
    max-width: 28em;
    overflow: hidden;
    position: relative;

    .film--hero & {
      font-size: 2.6rem;
      width: 50%;
      max-width: 50%;
      float: left;

      @media screen and (max-width: $bp-site-desktop) {
        font-size: 2rem;
      }

      @media screen and (max-width: 920px) {
        width: 100%;
        max-width: 100%;
        float: none;
      }

      @media screen and (max-width: $bp-films-2up-break) {
        font-size: 1.8rem;
      }

    }
  }

  &__watch {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 1.4rem;
    margin-right: 3.8rem;

    .icon--play {
      color: $c-primary;
      margin-right: 0.6em;
      position: relative;
      top: 0.1em;
    }
  }

  &__actions {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .film--hero & {
      margin-top: 0;
      width: 50%;
      float: right;
      justify-content: center;

      @media screen and (max-width: 920px) {
        justify-content: flex-start;
        width: auto;
        float: none;
        margin-top: 6rem;
      }

    }

    @media screen and (max-width: $bp-site-desktop) {
      margin-top: 4rem;
    }

  }

  &__readmore {

    .film--hero & {
      font-size: 2rem;

      @media screen and (max-width: $bp-films-2up-break) {
        font-size: 1.6rem;
      }
    }

  }

  &__social {
    display: flex;
    flex-direction: row;

    &__btn {
      line-height: 1;
      color: $c-primary;

      justify-content: center;
      align-items: center;
      display: inline-flex;

      width: 3.6rem;
      height: 3.6rem;
      padding: 0;

      margin-left: 1.4rem;
      &:first-of-type {
        margin-left: 0;
      }

      .icon--facebook { width: 1rem; }
      .icon--twitter { width: 1.8rem; margin-top: 3px; margin-left: 2px; }
      .icon--link { width: 2.1rem; margin-top: 2px; }
    }

  }
}
