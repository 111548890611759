.btn {
  line-height: 1.2;
  border: 1px solid $c-primary;
  border-radius: 3rem;
  padding: 0.8rem 2.2rem 0.7rem;
  color: $c-white;
  font-weight: $fw-book;
  font-size: 1.4rem;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;

  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  transition: $t-link-timing * 2 $t-link-easing;

  &:hover,
  &:active {
    color: $c-grey-2;
    background-color: $c-primary;
    border-color: $c-primary;

    .icon {
      color: currentColor;
    }
  }


  &--plain {
    border-width: 0 0 1px 0;
    color: currentColor;
    display: block;
    padding: 0;
    border-radius: 0;
    border-color: currentColor;
    font-size: inherit;
    text-transform: none;
    letter-spacing: inherit;
    font-weight: inherit;

    &:hover,
    &:active {
      background-color: inherit;
      color: $c-primary;
    }
  }
}
