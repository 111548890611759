.instagram-feed {

  @media screen and (max-width: $bp-nav-menu) {
    margin-bottom: 4rem;
  }

  &__inner {

  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    $width: #{46rem / $d-site * 100 + '%'};
    $margin-right: #{6rem / $d-site * 100 + '%'};
    $margin-bottom: #{12rem / $d-site * 100 + '%'};

    flex: 0 1 $width;
    margin-right: $margin-right;
    margin-bottom: $margin-bottom;

    &:nth-child(3n+0) {
      margin-right: 0;
    }

    @media screen and (max-width: $bp-instagram-break-1) {
      $width: #{72rem / $d-site * 100 + '%'};

      flex: 0 1 $width;

      &:nth-child(3n+0) {
        margin-right: $margin-right;
      }

      &:nth-child(2n+0) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: $bp-instagram-break-2) {
      flex: 0 1 100%;

      &:nth-child(n) {
        margin-right: 0;
        margin-bottom: 16vw;
      }

    }

    &--link {
      display: flex;
      align-items: center;
      justify-content: center;

      .bar-link {
        border: 1px solid $c-black;
        width: 100%;
        position: relative;
        height: 0;
        padding: 0 0 100%;

        a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media screen and (max-width: $bp-instagram-break-2) {
          padding: 0;
          height: auto;
          a {
            position: relative;
            padding: 3rem 2rem;; // initial value
          }
        }
      }
    }

  }

  &__instagram {
    font-size: 1.8rem;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 1.6rem;
    }
  }

  &__image {
    margin-bottom: 4.8rem;
    display: block;
    border-bottom: none;

    transition: $t-media-timing $t-media-easing filter;

    &:hover,
    &:focus {
      filter: $t-media-filter;
    }

    .pil {
      padding-bottom: 100%;
      background-color: $c-grey-2;
    }
    .blazy {
      background: transparent no-repeat center center;
      background-size: cover;
    }

    @media screen and (max-width: $bp-nav-menu) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    padding: 0 4rem;

    @media screen and (max-width: $bp-site-desktop) {
      padding: 0;
    }
  }

  &__text {
    a {
      color: inherit;

      &:hover {
        color: $c-primary;
        border-bottom-color: transparent;
      }
    }
  }

  &__date-link {
    font-size: 1.4rem;
    font-weight: $fw-semibold;
    letter-spacing: 0.1em;
    line-height: 1;
    text-transform: uppercase;
    border-bottom: none;
    margin-top: $d-spacer;
    display: inline-block;

    &:hover {
      color: $c-white;
    }

    .icon {
      margin-right: 0.6em;
      width: 1.8rem;
      top: 0.3em;
      position: relative;
    }

    @media screen and (max-width: $bp-nav-menu) {
      margin-top: 3rem;
    }
  }

}
