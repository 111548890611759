.container {
  max-width: calc(#{$d-site + ($d-gutter * 2)});
  margin-left: auto;
  margin-right: auto;
  padding-left: $d-gutter;
  padding-right: $d-gutter;

  @media screen and (max-width: $bp-site-desktop) {
    max-width: calc(#{$d-site-desktop + ($d-gutter * 2)});
  }

  @media screen and (max-width: $bp-site-small) {
    padding-left: $d-gutter / 2;
    padding-right: $d-gutter / 2;
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 0 auto;
}

.header {
  &__inner {
    padding-top: 8rem;
    padding-bottom: 8rem;

    @media screen and (max-width: $bp-nav-menu) {
      padding-top: $d-gutter;
      padding-bottom: $d-gutter;
    }
  }

}

.footer {
  background-color: $c-bg-hero;
  color: $c-text-dark;
  font-weight: $fw-light;
  font-size: $fs-footer;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  text-align: center;
  position: relative;

  a {
    color: currentColor;
    &:hover {
      color: $c-white;
    }
  }

  &__inner {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media screen and (max-width: $bp-nav-menu) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }

  &__social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 3.2rem 0;
    padding: 0;
    list-style-type: none;

    &__item {
      margin: 0 2rem;
    }

    &__link {
      border-bottom: none;
      color: $c-grey-4;

      &:hover,
      &:active {
        color: $c-white;
      }
    }
  }

  &__credits {
    margin-top: 1.4rem;
  }
}
