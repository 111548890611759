.js-vimeo-lightbox,
.js-lightbox {
  cursor: pointer;
}

.lightbox {
  opacity: 0;
  transform: scale(0);
  transition:
    0.3s ease-out opacity,
    0s 0.4s transform;

  background-color: $c-bg-hero;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  pointer-events: none;

  &.is-open {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
    transition:
      0.3s ease-out opacity,
      0s 0s transform;
  }

  &__content {
    display: flex;
    height: 100%;
    padding: $d-spacer;

    @media screen and (max-width: $bp-nav-menu) {
      padding: 2rem;
    }
  }

  &__media,
  &__figure {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 100%;
    position: relative;

    &__img {
      cursor: pointer;

      padding: 0 0;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex: 1 0 auto;

      &:after {
        content: "";
        height: 2.4rem;
        width: 2.4rem;
        border-radius: 50%;
        border-top: 1px solid transparent;
        border-left: 1px solid $c-primary;
        border-right: 1px solid $c-primary;
        border-bottom: 1px solid $c-primary;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        transition: 0.15s 0.1s ease;
        opacity: 1;

        .is-open & {
          opacity: 1;
          animation: spin 0.5s linear 0s infinite forwards;
        }

        .is-open.has-loaded & {
          opacity: 0;
          transition: 0s ease;
          animation: none;
        }

      }

      @media screen and (max-width: $bp-nav-menu) {
        padding-top: 5rem;
        padding-bottom: 2rem;
      }
    }

    &__img-content {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 100%;
      position: relative;

      opacity: 0;
      transition:
        0.05s ease transform,
        0.2s 0s ease opacity;

      .has-loaded & {
        opacity: 1;
        transition:
          0.05s ease transform,
          0.6s 0s ease opacity
      }

      img {
        max-height: 100%;
        padding: 0 12vw;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;

        opacity: 0;
        transition: 0s ease;

        @media screen and (max-width: $bp-nav-menu) {
          padding: 0;
        }

        .has-loaded & {
          opacity: 1;
        }

        &.is-removing {
          opacity: 0;
          .has-loaded & {
            opacity: 0;
          }
        }

        &.is-removed {
          visibility: hidden;
          display: none;
        }
      }

    }
  }

  &__backdrop {
    pointer-events: none;
    position: absolute;
    align-self: center;
    opacity: 0;
    transition: 0.6s 0s ease opacity;

    .icon {
      width: 20rem;
      stroke-width: 2;
    }

    .is-open & {
      opacity: 1;
    }
    .is-open.has-loaded & {
      opacity: 0;
      transition-duration: 0.2s;
    }
  }

  &__embed {
    height: 0;
    width: 100%;
    margin: auto;
    padding-bottom: 56.25%;
    overflow: hidden;

    position: relative;
    top: 0;
    left: 0;
    right: 0;

    .is-open & {
      background-color: transparent;

    }

    object,
    video,
    embed,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 8rem);

      opacity: 0;
      transition: 0.8s ease opacity;

      .has-loaded & {
        opacity: 1;
      }
    }
  }

  &__footer {
    font-size: 1.4rem;
    font-weight: $fw-book;
    line-height: 1.4;

    text-align: center;
    margin: $d-spacer auto 0;
    width: 100%;
    max-width: 40em;
    padding: 0;
    flex: 0 0 auto;

    opacity: 0;
    transform: translateY(3px);
    transition:
      0s 0s ease opacity,
      0s 0s ease transform;

    .has-loaded & {
      opacity: 1;
      transform: translateY(0);
      transition:
        0.6s 0.1s ease opacity,
        0.6s 0.1s ease transform;
    }

    a {
      border-bottom-color: currentColor;
      &:hover {
        border-bottom-color: transparent;
      }
    }

    @media screen and (max-width: 600px) {
      &.can-truncate {
        p {
          display: inline;
        }

        &:after {
          content: 'less';
          display: block;
          margin: 0.4em auto 0;
          font-weight: bold;
          font-size: 1.2rem;
          text-transform: uppercase;
        }

        &.is-truncated {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: auto;

          &:after {
            content: 'more';
            transform: rotateX(0deg);
          }
        }
      }
    }

  }

  &__date {
    color: $c-white;
    display: inline-block;
    margin-right: 0.4em;
  }

  &__caption {
    color: $c-text-caption;
    display: inline;
  }

  &__close,
  &__prev,
  &__next {
    color: $c-primary;

    &:hover,
    &:active {
      color: $c-white;
    }

  }

  &__close {
    width: 2.7rem;
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @media screen and (max-width: $bp-nav-menu) {
      // transform: translateY(0.8rem);
      // top: 2rem;
    }
  }

  &__prev,
  &__next {
    transition: 0.15s ease;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform-origin: 50% 50%;
    transform: translateY(-50%);

    @media screen and (max-width: $bp-nav-menu) {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }

    &[disabled] {
      opacity: 0.2;
    }

    .icon {
      width: 4.9rem;

      @media screen and (max-width: $bp-nav-menu) {
        width: 4rem;
      }
    }
  }

  &__next {
    margin-left: 0.8em;
    right: 0;
  }

  &__prev {
    transform: rotateZ(180deg) translateY(50%);
    left: 0;
  }

}
