.barcode {
  color: $c-text-barcode;
  font-weight: $fw-book;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  pointer-events: none;

  position: absolute;
  top: 0rem;
  right: calc(100% + #{$d-barcode-indent});

  .profile--main ~ &,
  .page-header ~ & {
    top: 1.8rem;
  }

  .section--indent & {
    @media screen and (max-width: $bp-nav-menu) {
      right: calc(100% + #{$d-barcode-indent / 2});
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 1rem;
    transform-origin: 100% 0%;
    transform: rotateZ(-90deg);
  }

  &__line {
    width: 1px;
    height: 100%;
    background-color: currentColor;
    display: block;
    margin-right: 0;
    opacity: 0;

    transition:
      0.8s 0.3s $easeOutExpo margin-right,
      0.8s 0.3s $easeOutExpo opacity;

    .has-js.no-fonts &,
    .has-js.has-fonts & {
      opacity: 1;
    }

    &:nth-child(0n+1) {
      .has-js.no-fonts &,
      .has-js.has-fonts & {
        margin-right: 9.3rem;
      }
    }
    &:nth-child(0n+2) {
      .has-js.no-fonts &,
      .has-js.has-fonts & {
        margin-right: 2.4rem;
      }
    }
  }

  &__text {
    margin-left: 2.6rem;
    white-space: nowrap;
  }
}
