.contact {

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__figure {
    margin-right: 11rem;
    width: 46rem;
    max-width: 100%;

    @media screen and (max-width: $bp-nav-social) {
      margin-right: 4rem;
    }

    @media screen and (max-width: $bp-nav-menu) {
      display: none;
      visibility: hidden;
    }

  }

  &__header {
    font-size: 3.6rem;
    margin-bottom: 6rem;
    max-width: 18em;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: $bp-nav-menu) {
      font-size: 2.2rem;
    }
  }

  &__link-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 1.4rem;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    line-height: 1;
  }

  &__link-item {
    flex: 1 0 50%;
    margin-bottom: $d-spacer;

    @media screen and (min-width: $bp-site-desktop) {
      &:nth-last-child(-n+2):not(:first-child) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: #{$bp-site-desktop + 1px}) {
      flex: 1 0 100%;
      &:nth-child(n) {
        margin-bottom: 3rem !important;
      }
    }
  }

  &__link {
    border-bottom: none;
    display: inline-flex;
    align-items: center;
    font-weight: $fw-book;

    &:hover {
      color: $c-white;
    }
  }

  &__icon {
    display: block;
    margin-right: 1em;
    min-width: 3rem;
    text-align: center;
  }

  &__label {
    @media screen and (max-width: $bp-nav-menu) {
      word-break: break-all;
      font-size: 1.2rem;
    }
  }
}
