.twitter-feed {

  text-align: center;

  &__inner {
    border: 1px solid $c-black;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    padding: 7.8rem 4rem;
    flex: 1 0 50%;

    @media screen and (max-width: $bp-nav-menu) {
      padding: 4rem 2rem;
      flex: 1 0 100%;
    }

  }

  &__tweet {
    font-size: 2.6rem;
    max-width: 18em;
    margin: auto;

    @media screen and (max-width: $bp-site-desktop) {
      font-size: 2rem;
    }

    @media screen and (max-width: $bp-nav-menu) {
      font-size: 1.8rem;
    }
  }

  &__text {
    a {
      color: inherit;

      &:hover {
        color: $c-primary;
        border-bottom-color: transparent;
      }
    }
  }

  &__date-link {
    font-weight: $fw-semibold;
    letter-spacing: 0.1em;
    font-size: 1.4rem;
    line-height: 1;
    text-transform: uppercase;
    border-bottom: none;

    &:hover {
      color: $c-white;
    }

    .icon {
      margin-right: 0.6em;
      width: 1.8rem;
      top: 0.15em;
      position: relative;
    }
  }

}
