.gallery {

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

  }

  &__item {
    $width: #{46rem / $d-site * 100 + '%'};
    $margin-right: #{6rem / $d-site * 100 + '%'};

    flex: 0 1 $width;
    margin-right: $margin-right;

    &:nth-child(3n+0) {
      margin-right: 0;
    }

    @media screen and (max-width: $bp-gallery-double) {
      $width: #{72rem / $d-site * 100 + '%'};
      flex: 0 1 $width;

      &:nth-child(3n+0) {
        margin-right: $margin-right;
      }

      &:nth-child(2n+0) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: $bp-gallery-single) {
      $width: 100%;
      flex: 0 1 $width;

      &:nth-child(n) {
        margin-right: 0;
      }
    }
  }

  &__image {
    $margin-bottom: #{8rem / 46rem * 100 + '%'};
    margin-bottom: $margin-bottom;

    &--portrait {
      $padding: #{6rem / 46rem * 100 + '%'};
      padding: 0 $padding;

      @media screen and (max-width: $bp-gallery-double) {
        padding: 0 #{4rem / 46rem * 100 + '%'};
      }

      @media screen and (max-width: $bp-gallery-single) {
        padding: 0 0;
      }
    }
  }

  &__footer {
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  &__date {
    color: $c-white;
    display: inline-block;
    margin-right: 0.4em;
  }

  &__caption {
    color: $c-text-caption;
    display: inline;
    line-height: 1.6rem;
  }

  .js-lightbox {
    transition: $t-media-timing $t-media-easing filter;

    &:hover,
    &:focus {
      filter: $t-media-filter;
    }
  }

}
