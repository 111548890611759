@charset "UTF-8";
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

/**
 * Address margins handled incorrectly in IE 6/7.
 */
body {
  margin: 0;
}

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.67em;
  margin: 2.33em 0;
}

/**
 * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
 * Address styling not present in IE 6/7/8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address margins set differently in IE 6/7.
 */
p,
pre {
  margin: 1em 0;
}

/**
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/**
 * Address CSS quotes not supported in IE 6/7.
 */
q {
  quotes: none;
}

/**
 * Address `quotes` property not supported in Safari 4.
 */
q:before,
q:after {
  content: '';
  content: none;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
   Lists
   ========================================================================== */
/**
 * Address margins set differently in IE 6/7.
 */
dl,
menu,
ol,
ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}

/**
 * Address paddings set differently in IE 6/7.
 */
menu,
ol,
ul {
  padding: 0 0 0 40px;
}

/**
 * Correct list images handled incorrectly in IE 7.
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: #CE693C;
}

::selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: #CE693C;
}

a::-moz-selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

a::selection {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

html, body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5;
  color: #CBCBCB;
  background-color: #1a1a1a;
  transition: 0.2s ease opacity;
  opacity: 0;
}
.has-js.no-fonts body, .has-js.has-fonts body {
  opacity: 1;
}
@media screen and (max-width: 1600px) {
  body {
    font-size: 1.6rem;
  }
}

img {
  max-width: 100%;
}

p strong {
  color: #ffffff;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: #CE693C;
  text-decoration: none;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) border-color, 0.4s cubic-bezier(0.19, 1, 0.22, 1) color;
  border-bottom: 1px solid currentColor;
  -webkit-tap-highlight-color: transparent;
}

a:focus,
a:hover {
  border-bottom-color: currentColor;
}

a:focus {
  outline: none;
}

button {
  border: 0;
  background: transparent;
  line-height: 1;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: currentColor;
  display: inline-block;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1) color;
}
button:focus, button:active {
  outline: none;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.btn {
  line-height: 1.2;
  border: 1px solid #CE693C;
  border-radius: 3rem;
  padding: 0.8rem 2.2rem 0.7rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn:hover, .btn:active {
  color: #1a1a1a;
  background-color: #CE693C;
  border-color: #CE693C;
}
.btn:hover .icon, .btn:active .icon {
  color: currentColor;
}
.btn--plain {
  border-width: 0 0 1px 0;
  color: currentColor;
  display: block;
  padding: 0;
  border-radius: 0;
  border-color: currentColor;
  font-size: inherit;
  text-transform: none;
  letter-spacing: inherit;
  font-weight: inherit;
}
.btn--plain:hover, .btn--plain:active {
  background-color: inherit;
  color: #CE693C;
}

.icon {
  display: inline-block;
  width: 2.4rem;
}
.icon--logo {
  width: 24.5rem;
}
@media screen and (max-width: 900px) {
  .icon--logo {
    width: 20rem;
  }
}
.icon--facebook {
  width: 1.4rem;
}
.icon--vimeo {
  width: 2.2rem;
}
.icon--arrow-ne {
  width: 1.2rem;
}
.icon--arrow-w {
  width: 1.8rem;
}
.icon--arrow-s {
  width: 1.1rem;
}
.icon--close {
  width: 2.7rem;
}
.icon--menu {
  width: 2.7rem;
}
.icon--play {
  width: 1rem;
}
.icon--play-btn {
  width: 15rem;
  height: 15rem;
}
.icon--logo-monogram {
  width: 35.7rem;
}
.icon--logo-monogram .letter-f {
  color: #CE693C;
}
.icon--chevron-down {
  width: 1.2rem;
}
.icon--map {
  width: 7.4rem;
}
.icon--map .pin {
  color: #CE693C;
}
.icon--map .pinhole {
  color: #000000;
}
.icon--world-map {
  width: 117.6rem;
}

.readmore__excerpt {
  display: none;
  visibility: hidden;
}
.is-truncated .readmore__excerpt {
  display: block;
  visibility: visible;
}
.readmore__full {
  display: block;
  visibility: visible;
}
.is-truncated .readmore__full {
  display: none;
  visibility: hidden;
}
.readmore__btn {
  font-size: 1.6rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 0.6em;
  color: #ffffff;
  font-weight: 300;
}
.readmore__btn .icon {
  margin-left: 0.2em;
  position: relative;
  top: -0.1em;
}
.readmore__btn .icon svg {
  transform: rotateX(-180deg);
  transform-origin: 50% 50%;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) transform;
}
.is-truncated .readmore__btn .icon svg {
  transform: rotateX(0deg);
}
@media screen and (max-width: 770px) {
  .readmore__btn {
    font-size: 1.6rem;
  }
}

.topo {
  color: #ffffff;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  width: 50%;
  overflow: hidden;
}
.topo--hero {
  color: #CE693C;
  opacity: 0.3;
}
@media screen and (max-width: 1200px) {
  .topo {
    width: 100% !important;
  }
}

.bar-link {
  letter-spacing: 0.18em;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid #000000;
  font-weight: 500;
}
.bar-link a {
  color: #ffffff;
  border: 0;
  line-height: 1;
  display: block;
  padding: 4.8rem 2rem;
  background-color: transparent;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) color, 0.8s cubic-bezier(0.19, 1, 0.22, 1) background-color;
}
@media screen and (max-width: 900px) {
  .bar-link a {
    padding: 3rem 2rem;
  }
}
.bar-link a .icon {
  margin-left: 0.4em;
  position: relative;
  top: 0.05em;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) transform;
  transform: translateY(0) translateX(0);
}
.bar-link a svg {
  color: #CE693C;
}
.bar-link a:hover {
  transition-duration: 0.4s, 1.6s;
  color: #CE693C;
  background-color: #000000;
}
.bar-link a:hover .icon {
  transform: translateY(0) translateX(0.7em);
}
.bar-link--external a:hover .icon {
  transform: translateY(-0.7em) translateX(0.7em);
}

.blazy {
  animation: none;
  opacity: 0;
}
.blazy.is-loaded {
  visibility: visible;
  animation: reveal 0.6s ease 0s 1 forwards;
}

.pil {
  position: relative;
  overflow: hidden;
  height: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #1D1D1D;
}
.pil > div,
.pil > img {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.barcode {
  color: #B3B3B3;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  pointer-events: none;
  position: absolute;
  top: 0rem;
  right: calc(100% + 6.4rem);
}
.profile--main ~ .barcode, .page-header ~ .barcode {
  top: 1.8rem;
}
@media screen and (max-width: 900px) {
  .section--indent .barcode {
    right: calc(100% + 3.2rem);
  }
}
.barcode__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 1rem;
  transform-origin: 100% 0%;
  transform: rotateZ(-90deg);
}
.barcode__line {
  width: 1px;
  height: 100%;
  background-color: currentColor;
  display: block;
  margin-right: 0;
  opacity: 0;
  transition: 0.8s 0.3s cubic-bezier(0.19, 1, 0.22, 1) margin-right, 0.8s 0.3s cubic-bezier(0.19, 1, 0.22, 1) opacity;
}
.has-js.no-fonts .barcode__line, .has-js.has-fonts .barcode__line {
  opacity: 1;
}
.has-js.no-fonts .barcode__line:nth-child(0n+1), .has-js.has-fonts .barcode__line:nth-child(0n+1) {
  margin-right: 9.3rem;
}
.has-js.no-fonts .barcode__line:nth-child(0n+2), .has-js.has-fonts .barcode__line:nth-child(0n+2) {
  margin-right: 2.4rem;
}
.barcode__text {
  margin-left: 2.6rem;
  white-space: nowrap;
}

.page-header {
  margin-bottom: 10rem;
}
@media screen and (max-width: 1600px) {
  .page-header {
    margin-bottom: 8rem;
  }
}
.page-header__title {
  font-weight: 600;
  color: #CE693C;
  margin-top: 0;
  margin-bottom: 0.9em;
  text-transform: uppercase;
  font-size: 3.6rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1600px) {
  .page-header__title {
    font-size: 2.8rem;
    margin-bottom: 1.1em;
  }
}
.page-header__intro {
  font-size: 3.6rem;
  max-width: 25em;
  line-height: 1.3;
}
@media screen and (max-width: 1600px) {
  .page-header__intro {
    font-size: 2.6rem;
    line-height: 1.45;
  }
}
.page-header__description {
  margin-top: 3.6rem;
  font-size: 1.8rem;
  max-width: 40em;
}
@media screen and (max-width: 1600px) {
  .page-header__description {
    font-size: 1.6rem;
  }
}

.section-title {
  margin-bottom: 10rem;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .section-title {
    margin-bottom: 6rem;
  }
}
.section-title__title {
  font-weight: 600;
  color: #ffffff;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 3.6rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1600px) {
  .section-title__title {
    font-size: 2.8rem;
  }
}

.js-vimeo-lightbox,
.js-lightbox {
  cursor: pointer;
}

.lightbox {
  opacity: 0;
  transform: scale(0);
  transition: 0.3s ease-out opacity, 0s 0.4s transform;
  background-color: #030303;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  pointer-events: none;
}
.lightbox.is-open {
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
  transition: 0.3s ease-out opacity, 0s 0s transform;
}
.lightbox__content {
  display: flex;
  height: 100%;
  padding: 4rem;
}
@media screen and (max-width: 900px) {
  .lightbox__content {
    padding: 2rem;
  }
}
.lightbox__media, .lightbox__figure {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
}
.lightbox__media__img, .lightbox__figure__img {
  cursor: pointer;
  padding: 0 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex: 1 0 auto;
}
.lightbox__media__img:after, .lightbox__figure__img:after {
  content: "";
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  border-top: 1px solid transparent;
  border-left: 1px solid #CE693C;
  border-right: 1px solid #CE693C;
  border-bottom: 1px solid #CE693C;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: 0.15s 0.1s ease;
  opacity: 1;
}
.is-open .lightbox__media__img:after, .is-open .lightbox__figure__img:after {
  opacity: 1;
  animation: spin 0.5s linear 0s infinite forwards;
}
.is-open.has-loaded .lightbox__media__img:after, .is-open.has-loaded .lightbox__figure__img:after {
  opacity: 0;
  transition: 0s ease;
  animation: none;
}
@media screen and (max-width: 900px) {
  .lightbox__media__img, .lightbox__figure__img {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
}
.lightbox__media__img-content, .lightbox__figure__img-content {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: 0.05s ease transform, 0.2s 0s ease opacity;
}
.has-loaded .lightbox__media__img-content, .has-loaded .lightbox__figure__img-content {
  opacity: 1;
  transition: 0.05s ease transform, 0.6s 0s ease opacity;
}
.lightbox__media__img-content img, .lightbox__figure__img-content img {
  max-height: 100%;
  padding: 0 12vw;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: 0s ease;
}
@media screen and (max-width: 900px) {
  .lightbox__media__img-content img, .lightbox__figure__img-content img {
    padding: 0;
  }
}
.has-loaded .lightbox__media__img-content img, .has-loaded .lightbox__figure__img-content img {
  opacity: 1;
}
.lightbox__media__img-content img.is-removing, .lightbox__figure__img-content img.is-removing {
  opacity: 0;
}
.has-loaded .lightbox__media__img-content img.is-removing, .has-loaded .lightbox__figure__img-content img.is-removing {
  opacity: 0;
}
.lightbox__media__img-content img.is-removed, .lightbox__figure__img-content img.is-removed {
  visibility: hidden;
  display: none;
}
.lightbox__backdrop {
  pointer-events: none;
  position: absolute;
  align-self: center;
  opacity: 0;
  transition: 0.6s 0s ease opacity;
}
.lightbox__backdrop .icon {
  width: 20rem;
  stroke-width: 2;
}
.is-open .lightbox__backdrop {
  opacity: 1;
}
.is-open.has-loaded .lightbox__backdrop {
  opacity: 0;
  transition-duration: 0.2s;
}
.lightbox__embed {
  height: 0;
  width: 100%;
  margin: auto;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}
.is-open .lightbox__embed {
  background-color: transparent;
}
.lightbox__embed object,
.lightbox__embed video,
.lightbox__embed embed,
.lightbox__embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 8rem);
  opacity: 0;
  transition: 0.8s ease opacity;
}
.has-loaded .lightbox__embed object, .has-loaded
.lightbox__embed video, .has-loaded
.lightbox__embed embed, .has-loaded
.lightbox__embed iframe {
  opacity: 1;
}
.lightbox__footer {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  margin: 4rem auto 0;
  width: 100%;
  max-width: 40em;
  padding: 0;
  flex: 0 0 auto;
  opacity: 0;
  transform: translateY(3px);
  transition: 0s 0s ease opacity, 0s 0s ease transform;
}
.has-loaded .lightbox__footer {
  opacity: 1;
  transform: translateY(0);
  transition: 0.6s 0.1s ease opacity, 0.6s 0.1s ease transform;
}
.lightbox__footer a {
  border-bottom-color: currentColor;
}
.lightbox__footer a:hover {
  border-bottom-color: transparent;
}
@media screen and (max-width: 600px) {
  .lightbox__footer.can-truncate p {
    display: inline;
  }
  .lightbox__footer.can-truncate:after {
    content: 'less';
    display: block;
    margin: 0.4em auto 0;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  .lightbox__footer.can-truncate.is-truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
  }
  .lightbox__footer.can-truncate.is-truncated:after {
    content: 'more';
    transform: rotateX(0deg);
  }
}
.lightbox__date {
  color: #ffffff;
  display: inline-block;
  margin-right: 0.4em;
}
.lightbox__caption {
  color: #7B7B7B;
  display: inline;
}
.lightbox__close, .lightbox__prev, .lightbox__next {
  color: #CE693C;
}
.lightbox__close:hover, .lightbox__close:active, .lightbox__prev:hover, .lightbox__prev:active, .lightbox__next:hover, .lightbox__next:active {
  color: #ffffff;
}
.lightbox__close {
  width: 2.7rem;
  align-self: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.lightbox__prev, .lightbox__next {
  transition: 0.15s ease;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .lightbox__prev, .lightbox__next {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
}
.lightbox__prev[disabled], .lightbox__next[disabled] {
  opacity: 0.2;
}
.lightbox__prev .icon, .lightbox__next .icon {
  width: 4.9rem;
}
@media screen and (max-width: 900px) {
  .lightbox__prev .icon, .lightbox__next .icon {
    width: 4rem;
  }
}
.lightbox__next {
  margin-left: 0.8em;
  right: 0;
}
.lightbox__prev {
  transform: rotateZ(180deg) translateY(50%);
  left: 0;
}

.section {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
@media screen and (max-width: 900px) {
  .section {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.section__inner {
  position: relative;
}
.section--indent .section__inner {
  margin-left: 6.4rem;
}
@media screen and (max-width: 900px) {
  .section--indent .section__inner {
    margin-left: 3.2rem;
  }
}
@media screen and (max-width: 460px) {
  .section--indent .section__inner {
    margin-left: 0;
  }
}
.section--lighter {
  background-color: #1D1D1D;
}

.nav {
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 500;
}
.nav__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav__logo {
  display: block;
  color: #ffffff;
  border: none;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) color;
}
.nav__logo path {
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.nav__logo:hover .icon, .nav__logo:focus .icon {
  color: #CE693C;
}
.nav__menu-btn {
  color: #CE693C;
}
.nav__menu-btn:hover, .nav__menu-btn:active {
  color: #ffffff;
}
.nav__menu-btn--open {
  display: none;
  visibility: hidden;
}
@media screen and (max-width: 900px) {
  .nav__menu-btn--open {
    display: block;
    visibility: visible;
  }
}
.nav__menu-btn--close {
  visibility: hidden;
  position: absolute;
  z-index: 5;
  top: calc(4rem + 1.4rem);
  right: 4rem;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s ease;
}
@media screen and (max-width: 900px) {
  .is-open .nav__menu-btn--close {
    visibility: visible;
    opacity: 1;
    pointer-events: initial;
  }
}
@media screen and (max-width: 600px) {
  .nav__menu-btn--close {
    right: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .nav__menu {
    visibility: hidden;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .nav__menu.is-open {
    visibility: visible;
  }
}
.nav__social, .nav__pages {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.nav__pages .topo {
  visibility: hidden;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}
@media screen and (max-width: 900px) {
  .nav__pages {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #030303;
    opacity: 0;
  }
  .is-open .nav__pages {
    transition: 0.2s ease;
    opacity: 1;
  }
  .nav__pages .topo {
    visibility: visible;
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .nav__social {
    display: none;
  }
}
.nav__item {
  margin: 0 2rem;
}
.nav__item--home {
  display: none;
}
.nav__social .nav__item {
  margin: 0 1.4rem;
}
@media screen and (max-width: 1600px) {
  .nav__social .nav__item {
    margin: 0 1rem;
  }
  .nav__social .nav__item .icon {
    width: 2rem;
  }
  .nav__social .nav__item .icon--facebook {
    width: 1.2rem;
  }
}
.nav__social .nav__item:last-child, .nav__pages .nav__item:last-child {
  margin-right: 0;
}
.nav__social .nav__item:first-child, .nav__pages .nav__item:first-child {
  margin-left: 0;
}
@media screen and (max-width: 1600px) {
  .nav__pages .nav__item {
    margin: 0 1.2rem;
  }
}
@media screen and (max-width: 900px) {
  .nav__pages .nav__item {
    display: flex !important;
    margin: .6em 0;
    width: 80%;
    text-align: center;
    font-size: 2rem;
  }
  .nav__pages .nav__item a {
    width: 100%;
    border-bottom: 0;
    padding: 1em 2em;
  }
  .nav__pages .nav__item a.is-active, .nav__pages .nav__item a:hover, .nav__pages .nav__item a:active {
    color: #ffffff;
  }
}
.nav__link {
  border-bottom-color: transparent;
  border-bottom-width: 2px;
  letter-spacing: 0.18em;
  padding: 0.4em 0.7em 0.4em 0.9em;
}
.nav__link--icon {
  display: block;
  border: none;
}
.nav__social .nav__link {
  padding-left: 0;
  padding-right: 0;
}
.nav__link.is-active, .nav__link:hover {
  border-bottom-color: currentColor;
}
.nav__social .nav__link.is-active, .nav__social .nav__link:hover {
  color: #ffffff;
}

.twitter-feed {
  text-align: center;
}
.twitter-feed__inner {
  border: 1px solid #000000;
}
.twitter-feed__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.twitter-feed__item {
  padding: 7.8rem 4rem;
  flex: 1 0 50%;
}
@media screen and (max-width: 900px) {
  .twitter-feed__item {
    padding: 4rem 2rem;
    flex: 1 0 100%;
  }
}
.twitter-feed__tweet {
  font-size: 2.6rem;
  max-width: 18em;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .twitter-feed__tweet {
    font-size: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .twitter-feed__tweet {
    font-size: 1.8rem;
  }
}
.twitter-feed__text a {
  color: inherit;
}
.twitter-feed__text a:hover {
  color: #CE693C;
  border-bottom-color: transparent;
}
.twitter-feed__date-link {
  font-weight: 600;
  letter-spacing: 0.1em;
  font-size: 1.4rem;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: none;
}
.twitter-feed__date-link:hover {
  color: #ffffff;
}
.twitter-feed__date-link .icon {
  margin-right: 0.6em;
  width: 1.8rem;
  top: 0.15em;
  position: relative;
}

@media screen and (max-width: 900px) {
  .instagram-feed {
    margin-bottom: 4rem;
  }
}
.instagram-feed__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.instagram-feed__item {
  flex: 0 1 30.66667%;
  margin-right: 4%;
  margin-bottom: 8%;
}
.instagram-feed__item:nth-child(3n+0) {
  margin-right: 0;
}
@media screen and (max-width: 900px) {
  .instagram-feed__item {
    flex: 0 1 48%;
  }
  .instagram-feed__item:nth-child(3n+0) {
    margin-right: 4%;
  }
  .instagram-feed__item:nth-child(2n+0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .instagram-feed__item {
    flex: 0 1 100%;
  }
  .instagram-feed__item:nth-child(n) {
    margin-right: 0;
    margin-bottom: 16vw;
  }
}
.instagram-feed__item--link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.instagram-feed__item--link .bar-link {
  border: 1px solid #000000;
  width: 100%;
  position: relative;
  height: 0;
  padding: 0 0 100%;
}
.instagram-feed__item--link .bar-link a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .instagram-feed__item--link .bar-link {
    padding: 0;
    height: auto;
  }
  .instagram-feed__item--link .bar-link a {
    position: relative;
    padding: 3rem 2rem;
  }
}
.instagram-feed__instagram {
  font-size: 1.8rem;
}
@media screen and (max-width: 1600px) {
  .instagram-feed__instagram {
    font-size: 1.6rem;
  }
}
.instagram-feed__image {
  margin-bottom: 4.8rem;
  display: block;
  border-bottom: none;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1) filter;
}
.instagram-feed__image:hover, .instagram-feed__image:focus {
  filter: grayscale(1) brightness(0.5) contrast(1.1);
}
.instagram-feed__image .pil {
  padding-bottom: 100%;
  background-color: #1a1a1a;
}
.instagram-feed__image .blazy {
  background: transparent no-repeat center center;
  background-size: cover;
}
@media screen and (max-width: 900px) {
  .instagram-feed__image {
    margin-bottom: 3rem;
  }
}
.instagram-feed__content {
  padding: 0 4rem;
}
@media screen and (max-width: 1600px) {
  .instagram-feed__content {
    padding: 0;
  }
}
.instagram-feed__text a {
  color: inherit;
}
.instagram-feed__text a:hover {
  color: #CE693C;
  border-bottom-color: transparent;
}
.instagram-feed__date-link {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: none;
  margin-top: 4rem;
  display: inline-block;
}
.instagram-feed__date-link:hover {
  color: #ffffff;
}
.instagram-feed__date-link .icon {
  margin-right: 0.6em;
  width: 1.8rem;
  top: 0.3em;
  position: relative;
}
@media screen and (max-width: 900px) {
  .instagram-feed__date-link {
    margin-top: 3rem;
  }
}

.contact__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.contact__figure {
  margin-right: 11rem;
  width: 46rem;
  max-width: 100%;
}
@media screen and (max-width: 1200px) {
  .contact__figure {
    margin-right: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .contact__figure {
    display: none;
    visibility: hidden;
  }
}
.contact__header {
  font-size: 3.6rem;
  margin-bottom: 6rem;
  max-width: 18em;
}
@media screen and (max-width: 1600px) {
  .contact__header {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 900px) {
  .contact__header {
    font-size: 2.2rem;
  }
}
.contact__link-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 1.4rem;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  line-height: 1;
}
.contact__link-item {
  flex: 1 0 50%;
  margin-bottom: 4rem;
}
@media screen and (min-width: 1600px) {
  .contact__link-item:nth-last-child(-n+2):not(:first-child) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1601px) {
  .contact__link-item {
    flex: 1 0 100%;
  }
  .contact__link-item:nth-child(n) {
    margin-bottom: 3rem !important;
  }
}
.contact__link {
  border-bottom: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
}
.contact__link:hover {
  color: #ffffff;
}
.contact__icon {
  display: block;
  margin-right: 1em;
  min-width: 3rem;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .contact__label {
    word-break: break-all;
    font-size: 1.2rem;
  }
}

.gallery__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.gallery__item {
  flex: 0 1 30.66667%;
  margin-right: 4%;
}
.gallery__item:nth-child(3n+0) {
  margin-right: 0;
}
@media screen and (max-width: 800px) {
  .gallery__item {
    flex: 0 1 48%;
  }
  .gallery__item:nth-child(3n+0) {
    margin-right: 4%;
  }
  .gallery__item:nth-child(2n+0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 500px) {
  .gallery__item {
    flex: 0 1 100%;
  }
  .gallery__item:nth-child(n) {
    margin-right: 0;
  }
}
.gallery__image {
  margin-bottom: 17.3913%;
}
.gallery__image--portrait {
  padding: 0 13.04348%;
}
@media screen and (max-width: 800px) {
  .gallery__image--portrait {
    padding: 0 8.69565%;
  }
}
@media screen and (max-width: 500px) {
  .gallery__image--portrait {
    padding: 0 0;
  }
}
.gallery__footer {
  margin-top: 2rem;
  font-size: 1.4rem;
  line-height: 1.4;
}
.gallery__date {
  color: #ffffff;
  display: inline-block;
  margin-right: 0.4em;
}
.gallery__caption {
  color: #7B7B7B;
  display: inline;
  line-height: 1.6rem;
}
.gallery .js-lightbox {
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1) filter;
}
.gallery .js-lightbox:hover, .gallery .js-lightbox:focus {
  filter: grayscale(1) brightness(0.5) contrast(1.1);
}

.profile .page-header {
  margin-bottom: 6rem;
}
.profile .page-header__title {
  color: #ffffff;
}
.profile__inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (max-width: 1100px) {
  .profile__inner {
    flex-wrap: wrap;
  }
}
.profile__image {
  max-width: 46rem;
  flex: 1 0 100%;
  margin-right: 12rem;
  margin-bottom: 8rem;
}
@media screen and (max-width: 1600px) {
  .profile__image {
    margin-right: 8rem;
    max-width: 42rem;
  }
}
@media screen and (max-width: 1100px) {
  .profile__image {
    order: 2;
    margin-right: 0;
    margin-bottom: 6rem;
  }
}
.profile__details {
  max-width: 60rem;
}
.profile__details a {
  border-bottom-color: transparent;
}
.profile__details a:hover, .profile__details a:active {
  border-bottom-color: currentColor;
}
.profile__details blockquote {
  margin: 1em 5rem 2em;
}
@media screen and (max-width: 1600px) {
  .profile__details blockquote {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .profile__details blockquote {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media screen and (max-width: 1100px) {
  .profile__details {
    max-width: none;
    margin-bottom: 0rem;
  }
}
.profile__details--additional {
  margin-bottom: 6rem;
}
.profile--main {
  justify-content: space-between;
}
.profile--main .page-header__title {
  color: #CE693C;
}
.profile--main .profile__image {
  order: 2;
  max-width: 60rem;
  margin-right: 0;
  margin-left: 12rem;
}
@media screen and (max-width: 1600px) {
  .profile--main .profile__image {
    margin-left: 8rem;
    max-width: 42rem;
  }
}
@media screen and (max-width: 1100px) {
  .profile--main .profile__image {
    margin-left: 0;
  }
}
.profile--main .profile__readmore {
  font-size: 2rem;
}
@media screen and (max-width: 770px) {
  .profile--main .profile__readmore {
    font-size: 1.6rem;
  }
}

.team-members__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 820px) {
  .team-members__list {
    flex-direction: column;
    align-items: center;
  }
}
.team-members__item {
  flex: 0 1 46%;
  width: 50%;
  padding-right: 4rem;
  margin-bottom: 10rem;
}
.team-members__item:nth-child(2n+0) {
  margin-right: 0;
  padding-right: 0;
}
@media screen and (max-width: 1600px) {
  .team-members__item {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 820px) {
  .team-members__item {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    flex: none;
  }
}

.team-member {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (max-width: 1600px) {
  .team-member {
    flex-direction: column;
  }
}
@media screen and (max-width: 820px) {
  .team-member {
    text-align: center;
  }
}
.team-member__image {
  display: block;
  border: 0;
  flex: 1 0 auto;
  width: 33rem;
  max-width: 33rem;
  height: 33rem;
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  margin-right: 6rem;
  position: relative;
  overflow: hidden;
}
.team-member__image--link {
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1) filter;
}
@media screen and (max-width: 1600px) {
  .team-member__image {
    margin-right: 0;
  }
}
@media screen and (max-width: 820px) {
  .team-member__image {
    width: 33rem;
    height: 33rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }
}
.team-member__image--link:hover, .team-member__image--link:focus {
  filter: grayscale(1) brightness(0.5) contrast(1.1);
}
.team-member__image .figure {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.team-member__content {
  margin-top: 3.4rem;
}
.team-member__title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.8em;
  text-transform: uppercase;
  font-size: 2.6rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
.team-member__role {
  display: inline-block;
  margin-right: 0.1em;
}
.team-member__role:not(:last-of-type):after {
  content: "•";
  display: inline-block;
  margin-left: 0.3em;
}
.team-member__social {
  margin-top: 4rem;
}
.team-member__social .contact__link__item:last-of-type {
  margin-bottom: 0;
}

.films__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.films__item {
  flex: 0 1 30.66667%;
  margin-right: 4%;
  margin-bottom: 10%;
}
.films__item:nth-child(3n+0) {
  margin-right: 0;
}
.films--2 .films__item {
  flex: 0 1 48%;
  margin-right: 4%;
}
.films--2 .films__item:nth-child(2n+0) {
  margin-right: 0;
}
@media screen and (max-width: 770px) {
  .films--2 .films__item {
    width: 100%;
    flex: 1 0 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 1060px) {
  .films--3 .films__item {
    flex: 0 1 48%;
    margin-right: 4%;
  }
  .films--3 .films__item:nth-child(2n+0) {
    margin-right: 0;
  }
}
@media screen and (max-width: 770px) {
  .films--3 .films__item {
    width: 100%;
    flex: 1 0 100%;
    margin-right: 0;
  }
}

.film__image {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.film__image .pil {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
}
.film__image .figure {
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1) filter;
}
.film__image .js-vimeo-lightbox:hover .figure,
.film__image .js-vimeo-lightbox:focus .figure {
  filter: grayscale(1) brightness(0.5) contrast(1.1);
}
.film__image .icon--play-btn {
  color: #ffffff;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1) color;
}
.film--hero .film__image .icon--play-btn {
  width: 30rem;
  height: 30rem;
  left: 75%;
  transform: translateX(calc(-50% / 0.75));
}
@media screen and (max-width: 1600px) {
  .film--hero .film__image .icon--play-btn {
    width: 19rem;
    height: 19rem;
  }
}
@media screen and (max-width: 900px) {
  .film--hero .film__image .icon--play-btn {
    left: 0;
    right: 0;
    transform: translateX(0);
  }
}
@media screen and (max-width: 770px) {
  .film--hero .film__image .icon--play-btn {
    width: 15rem;
    height: 15rem;
  }
}
@media screen and (max-width: 440px) {
  .film__image .icon--play-btn {
    transform: translateX(0) scale(0.8) !important;
  }
}
.film__image .js-vimeo-lightbox:hover .icon--play-btn,
.film__image .js-vimeo-lightbox:focus .icon--play-btn {
  color: #CE693C;
}
.film__play-btn {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.film__details {
  margin-top: 6rem;
  padding-left: 3rem;
  padding-right: 3rem;
  overflow: hidden;
}
.films--2 .film__details {
  padding-left: 6rem;
  padding-right: 6rem;
}
@media screen and (max-width: 1600px) {
  .films--2 .film__details {
    margin-top: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 1200px) {
  .films--2 .film__details {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 1600px) {
  .films--3 .film__details {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 1060px) {
  .films--3 .film__details {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .films--3 .film__details {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.film--hero .film__details {
  margin-top: 8rem;
  overflow: auto;
  padding-left: 6rem;
  padding-right: 6rem;
}
@media screen and (max-width: 1600px) {
  .film--hero .film__details {
    margin-top: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .film--hero .film__details {
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.film__label {
  font-size: 1.4rem;
  color: #CE693C;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.18em;
}
.film--hero .film__label {
  font-size: 1.8rem;
  margin-bottom: 4.6rem;
}
@media screen and (max-width: 1600px) {
  .film--hero .film__label {
    margin-bottom: 2.2rem;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 770px) {
  .film--hero .film__label {
    margin-bottom: 0.8rem;
  }
}
.film__label:empty:after {
  content: '—';
  display: block;
}
.film__title {
  font-size: 3.6rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 3rem;
  line-height: 1.1;
  letter-spacing: 0.05em;
}
.film--hero .film__image .film__title {
  font-size: 8rem;
  position: absolute;
  top: 50%;
  left: 0rem;
  z-index: 3;
  padding: 0 6rem;
  transform: translateY(-50%);
  max-width: calc(50% + 6rem);
  text-align: left;
  margin: 0;
}
@media screen and (max-width: 1600px) {
  .film--hero .film__image .film__title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 900px) {
  .film--hero .film__image .film__title {
    display: none;
    visibility: hidden;
  }
}
.film--hero .film__details .film__title {
  visibility: hidden;
  display: none;
  font-size: 3.6rem;
}
@media screen and (max-width: 900px) {
  .film--hero .film__details .film__title {
    visibility: visible;
    display: block;
  }
}
@media screen and (max-width: 770px) {
  .film--hero .film__details .film__title {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 1600px) {
  .film__title {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 900px) {
  .film__title {
    margin-bottom: 2rem;
  }
}
.film__description {
  max-width: 28em;
  overflow: hidden;
  position: relative;
}
.film--hero .film__description {
  font-size: 2.6rem;
  width: 50%;
  max-width: 50%;
  float: left;
}
@media screen and (max-width: 1600px) {
  .film--hero .film__description {
    font-size: 2rem;
  }
}
@media screen and (max-width: 920px) {
  .film--hero .film__description {
    width: 100%;
    max-width: 100%;
    float: none;
  }
}
@media screen and (max-width: 770px) {
  .film--hero .film__description {
    font-size: 1.8rem;
  }
}
.film__watch {
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 1.4rem;
  margin-right: 3.8rem;
}
.film__watch .icon--play {
  color: #CE693C;
  margin-right: 0.6em;
  position: relative;
  top: 0.1em;
}
.film__actions {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.film--hero .film__actions {
  margin-top: 0;
  width: 50%;
  float: right;
  justify-content: center;
}
@media screen and (max-width: 920px) {
  .film--hero .film__actions {
    justify-content: flex-start;
    width: auto;
    float: none;
    margin-top: 6rem;
  }
}
@media screen and (max-width: 1600px) {
  .film__actions {
    margin-top: 4rem;
  }
}
.film--hero .film__readmore {
  font-size: 2rem;
}
@media screen and (max-width: 770px) {
  .film--hero .film__readmore {
    font-size: 1.6rem;
  }
}
.film__social {
  display: flex;
  flex-direction: row;
}
.film__social__btn {
  line-height: 1;
  color: #CE693C;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0;
  margin-left: 1.4rem;
}
.film__social__btn:first-of-type {
  margin-left: 0;
}
.film__social__btn .icon--facebook {
  width: 1rem;
}
.film__social__btn .icon--twitter {
  width: 1.8rem;
  margin-top: 3px;
  margin-left: 2px;
}
.film__social__btn .icon--link {
  width: 2.1rem;
  margin-top: 2px;
}

.page--home .header {
  position: relative;
  z-index: 4;
}
.page--home .section--hero {
  padding: calc(21.8rem - 2.5rem) 4rem 9rem;
  margin-top: -21.8rem;
  overflow: hidden;
  position: relative;
  background-color: #030303;
}
@media screen and (max-width: 900px) {
  .page--home .section--hero {
    padding-top: 21.8rem;
  }
}
@media screen and (max-width: 1100px) {
  .page--home .section--hero {
    padding-bottom: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .page--home .section--hero {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.page--home .section--hero .section__inner {
  max-width: 180rem;
  margin-left: auto;
  margin-right: auto;
}
.page--home .section--hero .topo--hero {
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0.3;
  z-index: 2;
  position: absolute;
  bottom: 0;
  margin: auto;
  text-align: center;
}
.page--home .section--hero .topo--hero svg {
  height: 100%;
}
.page--home .section--latest-film {
  padding-top: 9rem;
  padding-bottom: 16rem;
}
@media screen and (max-width: 900px) {
  .page--home .section--latest-film {
    padding-bottom: 10rem;
  }
}
.page--home .section--recent-films {
  padding-top: 0;
  padding-bottom: 0;
}
.page--home .section--social {
  padding-top: 12rem;
  padding-bottom: 16rem;
  position: relative;
}
.page--home .section--social .topo--one {
  top: 0;
  left: 0;
}
.page--home .section--social .topo--two {
  right: 0;
  top: 50%;
}
@media screen and (max-width: 900px) {
  .page--home .section--social {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.page--home .section--contact {
  padding-bottom: 18rem;
  position: relative;
  overflow: hidden;
}
.page--home .section--contact .topo {
  top: 0;
  left: 0;
  width: 30%;
}
@media screen and (max-width: 900px) {
  .page--home .section--contact {
    padding-bottom: 6rem;
    text-align: center;
  }
  .page--home .section--contact .contact__header {
    margin-left: auto;
    margin-right: auto;
  }
}
.page--home .films__item {
  margin-bottom: 12rem;
}
@media screen and (max-width: 900px) {
  .page--home .films__item {
    margin-bottom: 6rem;
  }
}
.page--about .header {
  position: relative;
  z-index: 1;
}
.page--about .section--mainprofile {
  padding-top: calc(4rem + 21.8rem);
  margin-top: -21.8rem;
  overflow: hidden;
  position: relative;
}
.page--about .section--mainprofile .topo {
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
}
.page--about .section--mainprofile .topo svg {
  height: 100%;
  transform: translateX(-10%);
}
@media screen and (max-width: 1200px) {
  .page--about .section--mainprofile .topo svg {
    height: auto;
    transform: none;
  }
}
.page--about .section--otherprofiles {
  position: relative;
  overflow: hidden;
  padding-bottom: 16rem;
}
.page--about .section--otherprofiles .topo {
  right: 0;
  bottom: 0;
  width: 50vw;
  color: #ffffff;
}
@media screen and (max-width: 900px) {
  .page--about .section--otherprofiles {
    padding-bottom: 6rem;
  }
}
.page--about .section--world-map {
  padding-top: 15rem;
  padding-bottom: 15rem;
  overflow: hidden;
}
.page--about .section--world-map .section__inner,
.page--about .section--world-map .container {
  height: 100%;
}
.page--about .section--world-map .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 1200px) {
  .page--about .section--world-map .container {
    justify-content: center;
  }
}
.page--about .section--world-map .page-header {
  padding-top: 25rem;
  padding-bottom: 25rem;
  position: relative;
  z-index: 1;
  max-width: 56rem;
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .page--about .section--world-map .page-header {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.page--about .section--world-map .page-header__title {
  color: #ffffff;
}
.page--about .section--world-map .icon--world-map {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100vw;
  max-width: 150rem;
  min-width: 120rem;
}
@media screen and (max-width: 900px) {
  .page--about .section--world-map .icon--world-map {
    left: calc(50vw + 10rem);
  }
}
.page--films .section--pageheader {
  padding-top: 6rem;
  padding-bottom: 0;
}
.page--films .section--pageheader .topo {
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
}
.page--films .section--pageheader .topo svg {
  height: 100%;
  transform: translateX(-10%);
}
@media screen and (max-width: 1200px) {
  .page--films .section--pageheader .topo svg {
    height: auto;
    transform: none;
  }
}
.page--films .section--featuredfilms {
  padding-top: 0;
  padding-bottom: 0;
}
.page--films .section--otherfilms {
  padding-top: 13rem;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}
.page--films .section--otherfilms .topo--one {
  right: 0;
  top: 0;
}
.page--films .section--otherfilms .topo--four {
  left: 0;
  bottom: 0;
  width: 40rem;
}
@media screen and (max-width: 1200px) {
  .page--films .section--otherfilms .topo--four {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .page--films .section--otherfilms {
    padding-top: 6rem;
  }
}
.page--stills .section--pageheader {
  padding-top: 6rem;
  padding-bottom: 0;
}
.page--stills .section--pageheader .topo {
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
}
.page--stills .section--pageheader .topo svg {
  height: 100%;
  transform: translateX(-10%);
}
@media screen and (max-width: 1200px) {
  .page--stills .section--pageheader .topo svg {
    height: auto;
    transform: none;
  }
}
@media screen and (max-width: 900px) {
  .page--stills .section--pageheader {
    padding-top: 3rem;
  }
}
.page--stills .section--gallery {
  padding-top: 7rem;
  position: relative;
  overflow: hidden;
}
.page--stills .section--gallery .topo {
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 900px) {
  .page--stills .section--gallery {
    padding-top: 0;
  }
}
.page--contact .section--pageheader {
  padding-top: 6rem;
  padding-bottom: 22rem;
}
.page--contact .section--pageheader .topo {
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
}
.page--contact .section--pageheader .topo svg {
  height: 100%;
  transform: translateX(-10%);
}
@media screen and (max-width: 1200px) {
  .page--contact .section--pageheader .topo svg {
    height: auto;
    transform: none;
  }
}
@media screen and (max-width: 900px) {
  .page--contact .section--pageheader {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
}

.hero-carousel-wrapper {
  position: relative;
}

.hero-carousel {
  height: 0;
  padding-bottom: 48.35294%;
}
@media screen and (max-width: 900px) {
  .hero-carousel {
    position: relative;
  }
}
.hero-carousel .swiper-wrapper {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.hero-carousel .swiper-wrapper:after {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.hero-carousel__slide {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1D1D1D;
  transition: 0s transform, 2s ease-out opacity !important;
}
.hero-carousel__slide.swiper-lazy {
  opacity: 0 !important;
}
.hero-carousel__slide.swiper-lazy-loaded.swiper-slide-active {
  opacity: 1 !important;
}
.hero-carousel__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .hero-carousel__overlay {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 1100px) {
  .hero-carousel__overlay {
    position: relative;
    bottom: auto;
    padding: 0;
  }
}
.hero-carousel__overlay__inner {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 6vw;
}
@media screen and (max-width: 1100px) {
  .hero-carousel__overlay__inner {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;
  }
}
.hero-carousel__overlay__inner .icon svg {
  stroke-width: 1;
}
.hero-carousel__overlay__inner .icon--logo-monogram {
  margin-right: 8%;
  max-width: 28rem;
}
@media screen and (max-width: 1100px) {
  .hero-carousel__overlay__inner .icon--logo-monogram {
    margin: auto;
    width: 30vw;
    position: absolute;
    top: calc(-56.25vw + 5rem);
    height: 0;
    padding-bottom: 56.25vw;
  }
  .hero-carousel__overlay__inner .icon--logo-monogram svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
@media screen and (max-width: 1100px) and (max-width: 900px) {
  .hero-carousel__overlay__inner .icon--logo-monogram svg {
    stroke-width: 2;
  }
}

@media screen and (max-width: 900px) {
  .hero-carousel__overlay__inner .icon--logo-monogram {
    width: 30vw;
    min-width: 0;
  }
}
@media screen and (max-width: 600px) {
  .hero-carousel__overlay__inner .icon--logo-monogram {
    top: calc(-56.25vw + 3rem);
  }
}
.hero-carousel__overlay__content {
  font-size: 3.6rem;
  line-height: 1.2;
  max-width: 21em;
  color: #ffffff;
  flex: 1 0 70%;
  position: relative;
}
@media screen and (min-width: 1100px) and (min-height: 1200px) {
  .hero-carousel__overlay__content {
    flex: none;
  }
}
@media screen and (max-width: 1600px) {
  .hero-carousel__overlay__content {
    font-size: 2.8rem;
    padding: 4rem 0;
    line-height: 1.45;
  }
}
@media screen and (max-width: 600px) {
  .hero-carousel__overlay__content {
    font-size: 2.2rem;
  }
}
.hero-carousel__location {
  margin-top: 4rem;
  font-size: 2.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hero-carousel__location .icon {
  margin-right: 2.5rem;
  color: #ffffff;
  position: relative;
  top: 0.36em;
}
@media screen and (max-width: 1600px) {
  .hero-carousel__location {
    font-size: 2rem;
  }
  .hero-carousel__location .icon {
    width: 6rem;
  }
}
@media screen and (max-width: 600px) {
  .hero-carousel__location {
    font-size: 1.8rem;
  }
  .hero-carousel__location .icon {
    width: 5rem;
  }
}
.hero-carousel__btn {
  color: #CE693C;
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + 8rem);
  left: 0;
}
.hero-carousel__btn .icon {
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1) transform;
  margin-top: 3rem;
  transform: translateY(0);
}
.hero-carousel__btn:hover, .hero-carousel__btn:active {
  color: #ffffff;
  background-color: transparent;
}
.hero-carousel__btn:hover .icon, .hero-carousel__btn:active .icon {
  transform: translateY(0.7em);
}
@media screen and (max-width: 1600px) {
  .hero-carousel__btn {
    top: calc(100% + 4rem);
  }
}
@media screen and (max-width: 1200px) {
  .hero-carousel__btn {
    top: calc(100% + 2rem);
    flex-direction: row;
  }
  .hero-carousel__btn .icon {
    margin-top: 0;
    margin-left: 2rem;
  }
}
@media screen and (max-width: 1100px) {
  .hero-carousel__btn {
    position: relative;
    margin-top: 6rem;
    top: 0;
  }
}

.container {
  max-width: calc(158rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}
@media screen and (max-width: 1600px) {
  .container {
    max-width: calc(118rem);
  }
}
@media screen and (max-width: 600px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 0 auto;
}

.header__inner {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
@media screen and (max-width: 900px) {
  .header__inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.footer {
  background-color: #030303;
  color: #535353;
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  text-align: center;
  position: relative;
}
.footer a {
  color: currentColor;
}
.footer a:hover {
  color: #ffffff;
}
.footer__inner {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
@media screen and (max-width: 900px) {
  .footer__inner {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.footer__social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 3.2rem 0;
  padding: 0;
  list-style-type: none;
}
.footer__social__item {
  margin: 0 2rem;
}
.footer__social__link {
  border-bottom: none;
  color: #535353;
}
.footer__social__link:hover, .footer__social__link:active {
  color: #ffffff;
}
.footer__credits {
  margin-top: 1.4rem;
}


